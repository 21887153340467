import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {
  AcUnitRounded,
  AccessTimeRounded,
  EmailRounded,
  HailRounded,
  LuggageRounded,
  PhoneRounded,
} from "@mui/icons-material";
import "./contactusStyles.css";

const Contactus = () => {
  return (
    <div className="contactusContainer">
      <Navbar />
      <div className="mainContainer">
        <h1 className="title1">
          اجاره ماشین سورن تریپ
          <div className="underline"></div>
        </h1>

        <div className="baner">
          <img className="banerImg" src="/assets/city/shiraz/sadi.png" alt="" />
        </div>
        <h2 className="title1">
          راه های ارتباط با ما
          <div className="underline"></div>
        </h2>

        <div className="contactusCard">
          <h3 className="contactusCardTitle">تماس مستقیم</h3>
          <div className="contactusCardCenter">
            <div className="contactusOptions">
              <a className="link" href="tel:+989173349354">
                <div className="contactusOption">
                  <PhoneRounded className="contactusOptionIcon" />
                  <span>+989173349354</span>
                </div>
              </a>
              <div className="contactusOption">
                <EmailRounded className="contactusOptionIcon" />
                <span>SurenTrip@gmail.com</span>
              </div>
              <div className="contactusOption">
                <AccessTimeRounded className="contactusOptionIcon" />
                <span>پاسخگویی همه روزه 9 الی 23 شب</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;
