import { useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./vandetailsStyles.css";
import { useState } from "react";

const carTypeOptions = [
  {
    id: 1,
    image: "../assets/van/01.png",
    name: "ون دلیکا",
    year: "2017 - 2022",
    capacity: "10",
    luggage: "13",
    ac: "A/C",
    cost: "۳,۷۰۰,۰۰۰ تومان",
    cpk: "۱۷,۰۰۰ تومان",
    photos: [
      "../assets/carType/van/delica/delica01.png",
      "../assets/carType/van/delica/delica01.png",
      "../assets/carType/van/delica/delica01.png",
      "../assets/carType/van/delica/delica01.png",
    ],
  },
  {
    id: 2,
    image: "../assets/van/02.png",
    name: "ون دلیکا",
    year: "2017 - 2022",
    capacity: "10",
    luggage: "13",
    ac: "A/C",
    cost: "۳,۷۰۰,۰۰۰ تومان",
    cpk: "۱۷,۰۰۰ تومان",
    photos: [
      "../assets/van/02.png",
      "../assets/van/02.png",
      "../assets/van/02.png",
      "../assets/van/02.png",
    ],
  },
  {
    id: 3,
    image: "../assets/van/03.png",
    name: "ون دلیکا",
    year: "2017 - 2022",
    capacity: "10",
    luggage: "13",
    ac: "A/C",
    cost: "۳,۷۰۰,۰۰۰ تومان",
    cpk: "۱۷,۰۰۰ تومان",
    photos: [
      "../assets/van/03.png",
      "../assets/van/03.png",
      "../assets/van/03.png",
      "../assets/van/03.png",
    ],
  },
  {
    id: 4,
    image: "../assets/van/04.png",
    name: "ون دلیکا",
    year: "2017 - 2022",
    capacity: "10",
    luggage: "13",
    ac: "A/C",
    cost: "۳,۷۰۰,۰۰۰ تومان",
    cpk: "۱۷,۰۰۰ تومان",
    photos: [
      "../assets/van/04.png",
      "../assets/van/04.png",
      "../assets/van/04.png",
      "../assets/van/04.png",
    ],
  },
];

const Vandetails = ({ selectedItem }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(0);

  const { id } = useParams();
  const van = carTypeOptions.find((van) => van.id === parseInt(id));

  if (!van) return <div>Product not found</div>;

  const handlePhotoClick = (index) => {
    setSelectedPhoto(index);
  };

  return (
    <div className="vandetailsContainer">
      <Navbar />
      <div className="albumContainer">
        <h1>
          {van.name}
          <div className="underline"></div>
        </h1>

        <div className="albumPhotos">
          <div className="albumPhotoLarge">
            <img
              className="largePhotoImg"
              key={selectedPhoto}
              src={van.photos[selectedPhoto]}
              alt=""
            />
          </div>

          <div className="albumPhotoMedium">
            {van.photos.map((photoUrl, index) => {
              return (
                <div>
                  <img
                    key={index}
                    src={photoUrl}
                    alt={`عکس ${index + 1}`}
                    className={`photo ${
                      selectedPhoto === index ? "selected" : ""
                    }`}
                    onClick={() => handlePhotoClick(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Vandetails;
