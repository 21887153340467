// App.js
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/home/Home";
import Contactus from "./pages/contactus/Contactus";
import Vandetails from "./pages/vandetails/Vandetails";
import "./App.css";

export const shahr = [
  { city: "شیراز", type: "ون" },
  { city: "شیراز", type: "مینی بوس" },
  { city: "شیراز", type: "اتوبوس" },

  { city: "تهران", type: "ون" },
  { city: "تهران", type: "مینی بوس" },
  { city: "تهران", type: "اتوبوس" },

  { city: "اصفهان", type: "ون" },
  { city: "اصفهان", type: "مینی بوس" },
  { city: "اصفهان", type: "اتوبوس" },

  { city: "یزد", type: "ون" },
  { city: "یزد", type: "مینی بوس" },
  { city: "یزد", type: "اتوبوس" },

  { city: "کرمان", type: "ون" },
  { city: "کرمان", type: "مینی بوس" },
  { city: "کرمان", type: "اتوبوس" },

  { city: "مشهد", type: "ون" },
  { city: "مشهد", type: "مینی بوس" },
  { city: "مشهد", type: "اتوبوس" },

  { city: "تبریز", type: "ون" },
  { city: "تبریز", type: "مینی بوس" },
  { city: "تبریز", type: "اتوبوس" },
];

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home city={shahr[0]} />} />
          {shahr.map((city) => {
            return (
              <Route
                path={`/رزرو-${city.type}-در-${city.city}`}
                element={<Home city={city} />}
              />
            );
          })}
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/van/:id" element={<Vandetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
