import {
  Facebook,
  Instagram,
  LinkedIn,
  Telegram,
  WhatsApp,
  X,
} from "@mui/icons-material";
import "./footerStyles.css";
import React, { useState } from "react";
import { shahr } from "../../App";

const questions = [
  {
    question: "حداقل مدت‌زمان اجاره ون در شیراز چقدر است؟",
    answer: "باشید.",
  },
];

const Footer = () => {
  return (
    <>
      <div className="footerContainer">
        <div className="footerTop">
          <div className="footer">
            <div className="footerRight">
              <b>ما سورن تریپ هستیم</b>
              <br /> در اجاره برای ما سود بعد از رضایت مشتری اهمیت دارد. تیم
              حرفه ای ما شبانه روز در تلاش است تا فراتر از انتظارات شما عمل
              نماید. اطمینان میدهیم بهترین خدمات اجاره ماشین را داریم.
            </div>
            <div className="footerCenter">
              <img
                className="footerCenterImg"
                src="/assets/nemad/rentkonim-2.webp"
                alt=""
              />
              <img
                className="footerCenterImg"
                src="/assets/nemad/samandehi-2.webp"
                alt=""
              />
              <img
                className="footerCenterImg"
                src="/assets/nemad/tripadviser-2.webp"
                alt=""
              />
              <img
                className="footerCenterImg"
                src="/assets/nemad/worldTravelAward-2.webp"
                alt=""
              />
            </div>
            <div className="footerLeft">
              <WhatsApp className="footerLeftIcon" />
              <Telegram className="footerLeftIcon" />
              <Instagram className="footerLeftIcon" />
              <X className="footerLeftIcon" />
              <Facebook className="footerLeftIcon" />
              <LinkedIn className="footerLeftIcon" />
            </div>
            <div
              className="footerLeftt"
              style={{
                display: "flex",
                flexDirection: "column",
                direction: "rtl",
              }}
            >
              ما خدمات رزرو ون را در سراسر ایران از جمله
              <ul
                style={{
                  display: "flex",
                  flexDirection: "row",
                  direction: "rtl",
                  flexWrap: "wrap",
                  padding: 0,
                  listStyleType: "none",
                }}
              >
                {shahr.map((item) => {
                  return (
                    <li
                      style={{
                        width: "40%", // دو ستونه
                        marginTop: "5px", // فاصله بین آیتم‌ها
                        marginLeft: "20px",
                        boxSizing: "border-box", // برای اندازه‌گیری دقیق
                      }}
                    >
                      <a
                        style={{
                          minWidth: "150px",
                          textDecoration: "none",
                          display: "block",
                          padding: "1px", // کمی فضای داخلی
                          // برای بهبود ظاهری
                          // backgroundColor: "#aaa",
                          borderRadius: "5px", // کمی گرد کردن گوشه‌ها
                          color: "#fff",
                          fontSize: 14,
                        }}
                        href={`/رزرو-${item.type}-در-${item.city}`}
                      >
                        رزرو {item.type} در {item.city}
                      </a>
                    </li>
                  );
                })}
              </ul>
              و سایر شهرها ارائه می‌دهیم.
            </div>
          </div>
        </div>
        <div className="footerBottomLine">©2014-2024 SurenTrip Corporation</div>
      </div>
    </>
  );
};

export default Footer;
