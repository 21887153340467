import "./mainStyles.css";
import {
  AcUnitRounded,
  HailRounded,
  LuggageRounded,
} from "@mui/icons-material";
// import { Link, useNavigate } from "react-router-dom";
// import { useState } from "react";
// import Vandetails from "../../pages/vandetails/Vandetails";

const cityImages = {
  شیراز: "/assets/city/shiraz/shiraz02.png",
  تهران: "/assets/city/tehran/tehran02.png",
  اصفهان: "/assets/city/isfahan/isfahan02.jpg",
  یزد: "/assets/city/yazd/yazd04.png",
  کرمان: "/assets/city/kerman/kerman02.jpg",
  مشهد: "/assets/city/mashhad/mashhad01.jpg",
  تبریز: "/assets/city/tabriz/tabriz01.png",
};

const bus = [
  {
    id: 1,
    image: "../assets/carType/bus/01.png",
    name: "اتوبوس اقتصادی",
    year: "2017 - 2022",
    capacity: "44",
    luggage: "40",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 2,
    image: "../assets/carType/bus/02.png",
    name: "اتوبوس ویژه",
    year: "2017 - 2022",
    capacity: "25",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },

  {
    id: 2,
    image: "../assets/carType/bus/04.png",
    name: "اتوبوس تشریفاتی",
    year: "2017 - 2022",
    capacity: "25",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 2,
    image: "../assets/carType/bus/03.png",
    name: "اتوبوس ویژه",
    year: "2017 - 2022",
    capacity: "25",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 2,
    image: "../assets/carType/bus/05.png",
    name: "اتوبوس ویژه",
    year: "2017 - 2022",
    capacity: "25",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
];
const minibus = [
  {
    id: 1,
    image: "../assets/carType/minibus/01.png",
    name: "مینی بوس",
    year: "2017 - 2022",
    capacity: "19",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 2,
    image: "../assets/carType/minibus/02.png",
    name: "مینی بوس",
    year: "2017 - 2022",
    capacity: "24",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 3,
    image: "../assets/carType/minibus/03.png",
    name: "مینی بوس تشریفاتی",
    year: "2017 - 2022",
    capacity: "24",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },

  {
    id: 4,
    image: "../assets/carType/minibus/04.png",
    name: "مینی بوس",
    year: "2017 - 2022",
    capacity: "24",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 5,
    image: "../assets/carType/minibus/05.png",
    name: "مینی بوس",
    year: "2017 - 2022",
    capacity: "24",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 6,
    image: "../assets/carType/minibus/06.png",
    name: "مینی بوس",
    year: "2017 - 2022",
    capacity: "24",
    luggage: "25",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
];

const carTypeOptions = [
  {
    id: 6,
    image: "../assets/carType/van/03.jpg",
    name: "ون هایس ",
    year: "2017 - 2022",
    capacity: "13",
    luggage: "13",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 5,
    image: "../assets/carType/van/02.png",
    name: "ون هایس ",
    year: "2017 - 2022",
    capacity: "13",
    luggage: "13",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 4,
    image: "../assets/carType/van/01.png",
    name: "ون بنز ",
    year: "2017 - 2022",
    capacity: "13",
    luggage: "13",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
  {
    id: 3,
    image: "../assets/carType/van/hiace/hiaceFoton01.png",
    name: "ون هایس ",
    year: "2017 - 2022",
    capacity: "13",
    luggage: "13",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },

  {
    id: 2,
    image: "../assets/carType/van/hiace/hiace01.png",
    name: "ون هایس",
    year: "2017 - 2022",
    capacity: "13",
    luggage: "13",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
    // cost: "۳,۷۰۰,۰۰۰ تومان",
    // cpk: "۱۷,۰۰۰ تومان",
  },
  {
    id: 1,
    image: "../assets/carType/van/delica/delica01.png",
    name: "ون دلیکا",
    year: "2017 - 2022",
    capacity: "10",
    luggage: "13",
    ac: "A/C",
    cost: "لطفا برای اطلاع از هزینه های به روز با ما در تماس باشید",
    cpk: "09173349354",
  },
];

const Main = ({ city }) => {
  return (
    <>
      <div className="mainContainer">
        <h1 className="title1">
          اجاره {city.type} در {city.city}
          <div className="underline"></div>
        </h1>

        <section className="baner">
          <img
            className="banerImg"
            style={{ borderRadius: 20 }}
            src={cityImages[city.city] || "/assets/city/shiraz/shirazcity.png"}
            alt={`اجاره ${city.type} در ${city.city}`}
          />
        </section>
        {/* <section className="section1">
          <h2 className="title2">
            کرایه ون دربستی با راننده در شیراز
            <div className="underline"></div>
          </h2>
          <p className="pragraph1" style={{ whiteSpace: "pre-wrap" }}>
            <b>
              برای اجاره ون با راننده در شیراز لطفاً نکات زیر را با‌دقت مطالعه
              نمایید:
            </b>
            <br />
            <br />
            قیمت‌ اجاره ون با راننده در شیراز برای ون‌های زیر، کاملاً به‌روز
            است، اما به دلیل تنوع سرویس‌ها بهتر است جهت اطلاع دقیق از قیمت نهایی
            ون، با کارشناسان ما در واتس‌اپ در ارتباط باشید.
            <br />
            برای استعلام نهایی قیمت اجاره ون در شیراز، می‌بایست ون موردنظر +
            برنامه سفر یا گشت درون‌شهری + مبدأ و مقصد + تعداد روز یا ساعت
            موردنیاز خود را به همراه تاریخ دقیق از طریق واتس‌اپ برای کارشناسان
            ما ارسال نمایید.
            <br />
            بعد از ارسال درخواست اجاره ون در شیراز از طریق واتس‌اپ، حداکثر تا ۱
            ساعت، قیمت نهایی همراه با پیش‌فاکتور برای شما ارسال خواهد شد.
            <br />
            توجه داشته باشید که زمانی رزرو ون نهایی خواهد شد که کارشناسان ما
            برای شما پیش‌فاکتور ارسال کرده و پس از تأیید شما، مبلغ بیعانه
            به‌حساب مشخص در پیش‌فاکتور واریز شده باشد.
            <br />
            ساعت پاسخگویی و ارتباط با کارشناسان ما از طریق تماس تلفنی یا واتس‌اپ
            از ساعت ۹ صبح الی ۲۳ شب است.
            <br />
            قیمت اجاره ون‌های زیر بدون احتساب مالیات بر ارزش‌افزوده است و در
            صورت نیاز به فاکتور رسمی، ۹% به مبلغ اضافه خواهد شد.
            <br />
            تمامی سرویس‌های اجاره ون و اتوبوس این مجموعه با راننده قابل ارائه
            است و هیچ‌گونه سرویسی در خصوص اجاره ون در شیراز به‌صورت بدون راننده‌
            موجود نیست.
            <br />
            حداقل مدت‌زمان اجاره ون با راننده در شیراز، ۱۰ ساعت است که بهتر است
            نسبت به نهایی کردن رزرو خود از چند روز قبل اقدام کرده باشید.
            <br />
            برای رزرو ون با راننده در شیراز، به تعداد زیاد مثلا بیش از ۱۰ دستگاه
            به‌صورت هم‌زمان در یک‌رنگ و یک مدل، باید حداقل از یک هفته قبل
            درخواست خود را اعلام نمایید.
            <br />
            سرویس‌های خارج از شهر شیراز شامل هزینه خروج از شهر می‌شوند که این
            هزینه به مبلغ قیمت‌های زیر اضافه می‌گردد.
            <br />
            کنترل کیفیت: تمامی سرویس‌های این شرکت دارای ضمانت ارائه خدمات
            تشریفاتی با استانداردهای بین‌المللی هستند. در صورت به‌وجودآمدن
            کوچک‌ترین نارضایتی لطفاً از طریق ایمیل
            saadatrent.marketing@gmail.com اطلاع‌رسانی نمایید تا مدیریت مستقیم
            به شکایت شما رسیدگی نماید.
          </p>
        </section> */}
        <section className="section2">
          <div className="cards">
            {(city.type == "ون"
              ? carTypeOptions
              : city.type == "اتوبوس"
              ? bus
              : minibus
            ).map((item) => {
              return (
                <div>
                  <a className="link" href="tel:+989173349354">
                    {/* <Link className="link" to={`/van/${item.id}`}> */}
                    <div key={item.id} className="card">
                      <img
                        alt={item.name}
                        className="cardImg"
                        src={item.image}
                      />
                      <h3 className="cardTitle">{item.name}</h3>
                      <h3 className="cardTitleYear">{item.year}</h3>
                      <div className="options">
                        <div className="option">
                          <HailRounded className="optionIcon" />
                          <span>{item.capacity}</span>
                        </div>
                        <div className="option">
                          <LuggageRounded className="optionIcon" />
                          <span>{item.luggage}</span>
                        </div>
                        <div className="option">
                          <AcUnitRounded className="optionIcon" />
                          <span>{item.ac}</span>
                        </div>
                      </div>

                      <div className="costDetails">
                        <div className="item">
                          {/* <div className="costTitle">۱۰ ساعت در اختیار :</div> */}
                          <div className="cost">{item.cost}</div>
                        </div>
                        {/* <div className="item">
                          <div className="costTitle">کیلومتر برون شهری :</div>
                          <div className="cost">{item.cpk}</div>
                        </div> */}
                        <div className="item">
                          <div className="costTitle">شماره تماس: </div>
                          <div className="cost">{item.cpk}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default Main;
