import "./home.css";
import Navbar from "../../components/navbar/Navbar";
import Main from "../../components/main/Main";
import Questions from "../../components/questions/Questions";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";

export default function Home({ city }) {
  return (
    <div className="homeContainer">
      <Helmet>
        <title>
          اجاره {city.type} در {city.city} - بهترین خدمات کرایه {city.type} با
          راننده | سورن تریپ
        </title>
        <meta
          name="description"
          content={`اجاره ${city.type} در ${city.city} با بهترین قیمت و شرایط. کرایه ${city.type}ن‌های تشریفاتی و توریستی برای مسافران داخلی و خارجی. همراه با مشاوره تلفنی.`}
        />

        <meta
          name="keywords"
          content={`اجاره ${city.type} در ${city.city}, رزرو ${city.type} با راننده ${city.city}, کرایه ${city.type} در ${city.city}, اجاره ${city.type} هایس ${city.city}, اجاره ${city.type} بنز ${city.city}, کرایه ${city.type} تشریفاتی ${city.city}, اجاره ${city.type} برای سفر بر${city.type}‌شهری, قیمت اجاره ${city.type} ${city.city}, اجاره ${city.type} لوکس ${city.city}, کرایه ${city.type} ارزان در ${city.city}, اجاره ${city.type} 10 نفره ${city.city}, اجاره ${city.type} توریستی ${city.city}, کرایه ${city.type} برای مسافران خارجی ${city.city}, اجاره ${city.type} برای فرودگاه ${city.city}, کرایه ${city.type} برای تور ${city.city}, رزرو آنلاین ${city.type} در ${city.city}, اجاره ون برای گشت در${city.type}‌شهری, کرایه ون با کولر در ${city.city}, اجاره ${city.type} با ظرفیت بالا ${city.city}, کرایه ${city.type} بلندمدت ${city.city}, اجاره ${city.type} در فرودگاه ${city.city}, اجاره ${city.type} برای اماکن دیدنی ${city.city}, کرایه ${city.type} برای تور ${city.city} و تخت جمشید, اجاره ${city.type} در مناطق گردشگری ${city.city}, اجاره ${city.type} برای سفر به پاسارگاد, شرایط اجاره ${city.type} ${city.city}, مدارک لازم برای کرایه ${city.type} ${city.city}, قیمت کرایه ${city.type} با راننده, نحوه رزرو ${city.type} در ${city.city}, قوانین اجاره ${city.type} در ${city.city}`}
        />
      </Helmet>

      <Navbar />
      <Main city={city} />
      <Questions city={city} />
      <Footer />
    </div>
  );
}
