import React, { useState, useEffect } from "react";
import "./navbarStyles.css";
import {
  Language,
  PhoneInTalk,
  PhoneRounded,
  WhatsApp,
} from "@mui/icons-material";
import AirportShuttleRoundedIcon from "@mui/icons-material/AirportShuttleRounded";
import { Link } from "react-router-dom";

const Navbar = ({ city }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <div className="navbarContainer">
        <div className="navbarRight">
          <button className="hamburgerMenuButton" onClick={toggleMenu}>
            <div className={`bar ${menuOpen ? "open" : ""}`}></div>
            <div className={`bar ${menuOpen ? "open" : ""}`}></div>
            <div className={`bar ${menuOpen ? "open" : ""}`}></div>
          </button>
          <Link to="/">
            <AirportShuttleRoundedIcon
              style={{ fontSize: "45px", color: "#fff" }}
              className={`navbarRightLogo ${menuOpen ? "open" : ""}`}
            />
          </Link>
        </div>

        <div className={`sidebar ${menuOpen ? "open" : ""}`}>
          <ul className="sidebarUL">
            <li className="sidebarLi"> درباره ما</li>
            {/* <Link to="/reserve">رزرو ون</Link> */}
            <Link className="link" to="/contactus" city={city}>
              <li className="sidebarLi">تماس با ما</li>
            </Link>
          </ul>
        </div>

        <div className={`navbarCenter ${menuOpen ? "open" : ""}`}>
          <ul className="navbarCenterUL">
            <li className="navbarCenterLi"> درباره ما</li>
            <Link className="link" to="/contactus">
              <li className="navbarCenterLi">تماس با ما</li>
            </Link>
          </ul>
        </div>
        <div className="navbarLeft">
          <div className="navbarIcons">
            <Language style={{ fontSize: "23px" }} className="navbarIcon" />
            <a className="link" href="tel:+989173349354">
              <PhoneRounded
                style={{ fontSize: "23px" }}
                className="navbarIcon"
              />
            </a>
            <a className="link" href="https://wa.me/989173349354">
              <WhatsApp style={{ fontSize: "23px" }} className="navbarIcon" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
